import { forwardRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormControl } from '@chakra-ui/react'
import { Turnstile, TurnstileInstance } from '@marsidev/react-turnstile'
import { FormErrorMessage } from '@opengovsg/design-system-react'

import { TURNSTILE_ACTION } from '~shared/constants'

import { TURNSTILE_SITE_KEY } from '~constants/config'

export const TURNSTILE_TOKEN_FIELD_NAME = 'CALSG_TURNSTILE_TOKEN'

export const TurnstileField = forwardRef<TurnstileInstance>((_props, ref) => {
  const {
    formState: { errors },
  } = useFormContext()
  const [
    isTurnstileWaitingForInteraction,
    setIsTurnstileWaitingForInteraction,
  ] = useState(false)

  return (
    <FormControl isInvalid={!!errors[TURNSTILE_TOKEN_FIELD_NAME]} mt={0}>
      <Controller
        name={TURNSTILE_TOKEN_FIELD_NAME}
        rules={{
          // TODO(#584): Make this required all the time, not just
          // when Turnstile is visible
          required: isTurnstileWaitingForInteraction
            ? 'Please check the box above for security verification.'
            : undefined,
        }}
        render={({ field }) => (
          <Turnstile
            ref={ref}
            siteKey={TURNSTILE_SITE_KEY}
            options={{
              theme: 'light',
              appearance: 'interaction-only',
              action: TURNSTILE_ACTION,
            }}
            scriptOptions={{
              crossOrigin: 'anonymous',
            }}
            onSuccess={(token) => {
              field.onChange(token)
              setIsTurnstileWaitingForInteraction(false)
            }}
            // Library type for onError is wrong, Cloudflare docs say that
            // onError is called with the error code
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onError={(error: unknown) => {
              // TODO(#584): Show error toast
              console.error('Turnstile error', error)
              return true
            }}
            onExpire={() => {
              field.onChange('')
            }}
            onBeforeInteractive={() => {
              setIsTurnstileWaitingForInteraction(true)
            }}
            onAfterInteractive={() => {
              setIsTurnstileWaitingForInteraction(false)
            }}
            onUnsupported={() => {
              console.error('Turnstile error: Unsupported browser')
            }}
            style={{
              alignSelf: 'start',
              width: '100%',
              overflowX: 'auto',
            }}
          />
        )}
      />
      <FormErrorMessage>
        {errors[TURNSTILE_TOKEN_FIELD_NAME]?.message as string}
      </FormErrorMessage>
    </FormControl>
  )
})

TurnstileField.displayName = 'TurnstileField'
