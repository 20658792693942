import { useMutation, useQueryClient } from '@tanstack/react-query'

import { CreateBookingReq, GetPublicBookingRes } from '~shared/dto'
import { Timeslot } from '~shared/types'

import { api } from '~lib/api'
import { publicQueryKeys } from '~constants/queryKeys'

export type SubmitFormArgs = {
  eventId: string
  scheduleId: string
  slot: Timeslot
  // TODO(#584): Make turnstileToken required
} & Omit<CreateBookingReq, 'startAt' | 'endAt'>

export const submitForm = ({
  eventId,
  scheduleId,
  email,
  slot,
  responses,
  previousBookingId,
  turnstileToken,
}: SubmitFormArgs): Promise<GetPublicBookingRes> => {
  return api
    .url(`/events/${eventId}/schedules/${scheduleId}/bookings`)
    .post({
      startAt: slot.startAt,
      endAt: slot.endAt,
      email,
      responses,
      previousBookingId,
      turnstileToken,
    })
    .json()
}

export const useSubmitForm = ({
  eventId,
  scheduleId,
}: {
  eventId: string
  scheduleId: string
}) => {
  const queryClient = useQueryClient()
  return useMutation(submitForm, {
    onSettled: async () => {
      await queryClient.invalidateQueries(publicQueryKeys.event({ eventId }))
    },
    onError: async () => {
      await queryClient.invalidateQueries(publicQueryKeys.slots({ scheduleId }))
    },
  })
}
